.narrow {
  width: 900px;
  margin: 0 auto;
}

.page-padding {
  padding: 4rem;
}

.main-container {
  width: 100%;
  height: 100%;
  padding: var(--medium-spacing);
  display: flex;
  flex-direction: column;
}

.tab-navigator-container {
  width: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.tablist-header {
  margin-bottom: var(--small-spacing);
}

.tab-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
}

.main.page > .narrow > img {
  margin: 0 auto;
  display: block;
  width: 200px;
}

.main.page > .narrow > ul {
  width: 80%;
  justify-content: space-between;
  margin: 4rem auto;
  border-bottom: none;
}

.main.page > .narrow > ul > li {
  background-color: inherit;
  margin: auto;
}

.main.page > .narrow > ul > li > a {
  font-size: 14px;
  min-height: 32px;
  border-bottom-color: rgb(98, 100, 167);
}

.sections > * {
  margin: 4rem auto;
}

.tabList {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 30px 20px;
  row-gap: 30px;
}

pre,
div.error {
  background-color: #e5e5e5;
  padding: 1rem;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.2);
  border-radius: 3px;
  margin: 1rem 0;
  max-height: 200px;
  overflow-x: scroll;
  overflow-y: scroll;
  max-width: 732px;
}

pre.fixed,
div.error.fixed {
  height: 200px;
}

code {
  background-color: #e5e5e5;
  display: inline-block;
  padding: 0px 6px;
  border-radius: 3px;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.2);
}

code::-webkit-scrollbar,
pre::-webkit-scrollbar {
  display: none;
}

.contrast pre,
.contrast code,
.contrast div.error {
  background-color: #000000;
  border-color: #ffffff;
  border-width: thin;
  border-style: solid;
}

.dark pre,
.dark code,
.dark div.error {
  background-color: #1b1b1b;
}

.error {
  color: red;
}

.logo {
  max-width: 100px;
  object-fit: contain;
}

.header-text-column {
  margin-left: -100px;
}

@media (max-width: 400px) {
  .logo {
    max-width: 50px;
  }

  .header-text-column {
    margin-left: -50px;
  }
}
